<template>
  <div ref="line" class="line"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'LineCharts',
  props: {
    color1: {
      type: String,
      default: ''
    },
    color2: {
      type: String,
      default: ''
    },
    list_x: {
      type: Array,
      default: () => []
    },
    list_y: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    dataType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },

  mounted() {
    const myChart = echarts.init(this.$refs.line)
    let dataType = this.dataType;

    myChart.setOption({
      title: {
        subtext: '近30天趋势图',
        left: 'center'
      },
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        },
        formatter: function (params) {
          let str = '';
          params.forEach(item => {
            let value = item.value;
            if (!!dataType && dataType == 'percent') {
              value = parseFloat((value * 100).toFixed(2)) + '%'
            }
            str += item.name + "<br />" + item.marker + item.seriesName + "：" + value;
          })
          return str;
        }
      },
      xAxis: {
        show: false,
        type: 'category',
        data: this.list_x,
        axisTick: {
          alignWithLabel: true,
          show: true
        },
        axisLabel: { interval: 0, rotate: 30, show: true },
        axisLine: {
          //坐标轴轴线
          show: true
        }
      },
      yAxis: {
        show: false
      },
      series: [
        {
          name: this.title,
          type: 'line',
          smooth: true,
          data: this.list_y,
          // 折线拐点样式
          itemStyle: {
            opacity: 0
          },
          // 折线的样式
          lineStyle: {
            color: this.color1
          },
          // 区域填充颜色
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: this.color1 // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: this.color2 // 100% 处的颜色
                }
              ],
              global: false // 缺省为 false
            }
          }
        }
      ],
      grid: {
        left: '0%',
        right: '0%',
        top: '30%',
        bottom: '5%'
      }
    })
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.line {
  width: 100%;
  height: 100%;
}
</style>
