<template>
  <div class="wrapper">
    <div class="total">
      <div class="item">
        <div class="value">{{ detail.todayNum }}</div>
        <div class="label">今日新增合作门店</div>
      </div>
      <div class="item">
        <div class="value">{{ detail.totalNum }}</div>
        <div class="label">合作门店总量</div>
      </div>
    </div>
    <div class="list-box">
      <!-- 门店订单月榜 -->
      <div class="col">
        <div class="title">
          <span>门店订单月榜</span>
          <el-tooltip effect="dark" content="门店订单月榜" placement="top-start">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="list">
          <div class="list-item" v-for="(item, i) in detail.monthDealRankList" :key="i">
            <div class="col1">
              <img v-if="i == 0" src="~@/assets/icon/level1.png" />
              <img v-if="i == 1" src="~@/assets/icon/level2.png" />
              <img v-if="i == 2" src="~@/assets/icon/level3.png" />
              <span v-if="i > 2">{{ i + 1 }}</span>
            </div>
            <div class="col2">
              <div class="name">{{ item.storeName }}</div>
              <div class="num">合作天数：{{ item.cooperateDayNum }}</div>
            </div>
            <div class="col3">订单数：{{ item.orderDealNum }}</div>
            <div class="col4">订单金额：￥{{ item.orderDealAmount }}</div>
          </div>
          <el-empty v-if="!detail.monthDealRankList || detail.monthDealRankList.length == 0" description="暂无数据"></el-empty>
        </div>
      </div>
      <!-- 门店新星榜 -->
      <div class="col">
        <div class="title">
          <span>门店新星榜</span>
          <el-tooltip effect="dark" content="合作30天内的新门店" placement="top-start">
            <i class="el-icon-warning-outline"></i>
          </el-tooltip>
        </div>
        <div class="list">
          <div class="list-item" v-for="(item, i) in detail.freshDealRankList" :key="i">
            <div class="col1">
              <img v-if="i == 0" src="~@/assets/icon/level1.png" />
              <img v-if="i == 1" src="~@/assets/icon/level2.png" />
              <img v-if="i == 2" src="~@/assets/icon/level3.png" />
              <span v-if="i > 2">{{ i + 1 }}</span>
            </div>
            <div class="col2">
              <div class="name">{{ item.storeName }}</div>
              <div class="num">合作天数：{{ item.cooperateDayNum }}</div>
            </div>
            <div class="col3">订单数：{{ item.orderDealNum }}</div>
            <div class="col4">订单金额：￥{{ item.orderDealAmount }}</div>
          </div>
          <el-empty v-if="!detail.freshDealRankList || detail.freshDealRankList.length == 0" description="暂无数据"></el-empty>
        </div>
      </div>
    </div>
    <div style="height:50px"></div>
  </div>
</template>

<script>
export default {
  name: 'Store',
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.wrapper {
  .total {
    display: flex;
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      border: 1px solid #ebeef5;
      border-radius: 4px;
      padding: 10px 20px;
      margin-right: 20px;
      width: 220px;
      &:hover {
        background: #f0f2f5;
      }
      .label {
        color: #98a9bc;
      }
      .value {
        font-size: 24px;
        color: #252631;
      }
    }
  }
  .list-box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-top: 20px;
    .col {
      margin-right: 30px;
      .title {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        span {
          font-size: 14px;
          font-weight: 600;
          margin-right: 10px;
        }
        i {
          font-size: 18px;
        }
      }
      .list {
        border: 1px solid #ebeef5;
        border-radius: 4px;

        .list-item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #ebeef5;
          padding: 10px 20px;
          &:last-child {
            border-bottom: none;
          }
          .col1 {
            display: flex;
            align-items: center;
            font-size: 15px;
            font-weight: 600;
            padding: 0 5px;
            width: 70px;
            img {
              width: 40px;
              height: 24px;
            }
            span {
              width: 40px;
              display: block;
              text-align: center;
            }
          }
          .col2 {
            padding: 0 5px;
            flex: 2;
            .name {
              font-size: 15px;
              font-weight: 700;
            }
            .num {
              font-size: 13px;
            }
          }
          .col3 {
            padding: 0 5px;
            flex: 1;
          }
          .col4 {
            padding: 0 5px;
            flex: 1;
          }
        }
      }
    }
  }
}
</style>
