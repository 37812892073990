<template>
  <div ref="line" class="line"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'LineCharts',
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },

  mounted() {
    const myChart = echarts.init(this.$refs.line)

    myChart.setOption({
      grid: {
        left: '0',
        right: '0',
        bottom: '0',
        containLabel: true
      },
      tooltip: {
        trigger: 'item',
        formatter: function (params) {
          return params.marker + params.data.name + "：" + params.data.value;
        }
      },
      series: [
        {
          name: '客户类型',
          type: 'pie',
          radius: ['40%', '80%'],
          avoidLabelOverlap: false,
          label: {
            show: false,
            position: 'center'
          },
          labelLine: {
            show: false
          },
          data: this.list
        }
      ]
    })
  },

  methods: {}
}
</script>

<style lang="css" scoped>
.line {
  width: 100%;
  height: 100%;
}
</style>
