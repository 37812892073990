<template>
  <div class="wrapper">
    <div class="row">
      <div class="title">收入情况</div>
      <div class="list">
        <!-- 首付金额 -->
        <div class="item bg1 item animate__animated animate__zoomIn animate__faster">
          <div class="left">
            <span>
              <i class="el-icon-s-cooperation"></i>
            </span>
          </div>
          <div class="right">
            <div class="value">{{ detail.prepayAmount }}</div>
            <div class="label">首付金额</div>
          </div>
          <div class="tip">
            <el-tooltip effect="dark" content="今日首付（包含押金）金额总和" placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
        </div>
        <!-- 租金总回收金额 -->
        <div class="item bg2 item animate__animated animate__zoomIn animate__faster">
          <div class="left">
            <span>
              <i class="el-icon-s-order"></i>
            </span>
          </div>
          <div class="right">
            <div class="value">{{ detail.billRepayAmount }}</div>
            <div class="label">租金总回收金额</div>
          </div>
          <div class="tip">
            <el-tooltip effect="dark" content="当日支付的租金总金额" placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
        </div>
        <!-- 监管锁 -->
        <div class="item bg3 item animate__animated animate__zoomIn animate__faster">
          <div class="left">
            <span>
              <i class="el-icon-lock"></i>
            </span>
          </div>
          <div class="right">
            <div class="value">{{ detail.abmAmount }}</div>
            <div class="label">监管锁</div>
          </div>
          <div class="tip">
            <el-tooltip effect="dark" content="当日收取的监管锁费用" placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
        </div>
        <!-- 账单手续费 -->
        <div class="item bg4 item animate__animated animate__zoomIn animate__faster">
          <div class="left">
            <span>
              <i class="el-icon-money"></i>
            </span>
          </div>
          <div class="right">
            <div class="value">{{ detail.billCommissionAmount }}</div>
            <div class="label">账单手续费</div>
          </div>
          <div class="tip">
            <el-tooltip effect="dark" content="当日收取的账单手续费" placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="title">支出情况</div>
      <div class="list">
        <!-- 采购金额 -->
        <div class="item bg5 item animate__animated animate__zoomIn animate__faster">
          <div class="left">
            <span>
              <i class="el-icon-coin"></i>
            </span>
          </div>
          <div class="right">
            <div class="value">{{ detail.costAmount }}</div>
            <div class="label">采购金额</div>
          </div>
          <div class="tip">
            <el-tooltip effect="dark" content="支付时间为当天且没有取消的订单成本价之和" placement="top-start">
              <i class="el-icon-warning-outline"></i>
            </el-tooltip>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Shouzhi',
  props: {
    detail: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.wrapper {
  .row {
    .title {
      margin-bottom: 10px;
    }
    .list {
      display: grid;
      gap: 10px;
      grid-template-columns: repeat(auto-fit, minmax(200px, 300px));
      margin-bottom: 20px;
      .item {
        position: relative;
        display: flex;
        border: 1px solid #ebeef5;
        border-radius: 4px;
        padding: 10px 10px;
        &:hover {
          background: #f0f2f5;
        }
        .left {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 60px;
          height: 60px;
          border-radius: 50%;

          margin-right: 10px;
          span {
            display: flex;
            align-items: center;
            justify-content: center;

            width: 48px;
            height: 48px;
            border-radius: 50%;
            i {
              color: #fff;
              font-size: 26px;
            }
          }
        }
        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          .label {
            color: #98a9bc;
          }
          .value {
            font-size: 24px;
            color: #252631;
          }
        }
        .tip {
          position: absolute;
          top: 5px;
          right: 5px;
          i {
            font-size: 18px;
          }
        }
      }
      .bg1 {
        .left {
          background: #e4ecff;
          span {
            background: #4d7cfe;
          }
        }
      }
      .bg2 {
        .left {
          background: #fff3e0;
          span {
            background: #ffab2b;
          }
        }
      }
      .bg3 {
        .left {
          background: #eaf9e1;
          span {
            background: #6dd230;
          }
        }
      }
      .bg4 {
        .left {
          background: #ffeaf4;
          span {
            background: #ff85c0;
          }
        }
      }
      .bg5 {
        .left {
          background: #f1e4ff;
          span {
            background: #b37feb;
          }
        }
      }
    }
  }
}
</style>
