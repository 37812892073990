<template>
  <div class="wrapper">
    <!-- 有权限 -->
    <template v-if="is_auth">
      <!-- 日期 -->
      <div class="top">
        <div class="left">
          <admin-title title="核心数据"></admin-title>
        </div>
        <div class="right">
          <el-radio-group v-model="current_date" size="small">
            <el-radio-button :label="1">{{ today | todayName }}</el-radio-button>
            <el-radio-button :label="2">{{ today1 | todayName }}</el-radio-button>
            <el-radio-button :label="3">{{ today2 | todayName }}</el-radio-button>
          </el-radio-group>
          <div class="date">数据更新时间：{{ stat.refreshTime }}</div>
        </div>
      </div>
      <!-- 核心数据 -->
      <div class="hexin-box">
        <!-- 第一列 -->
        <div class="item bg1 item animate__animated animate__zoomIn animate__faster">
          <div class="row">
            <div class="left">
              <div class="title">
                <el-tooltip effect="dark" content="今日下单用户数量" placement="top-start">
                  <span> 日下单用户数</span>
                </el-tooltip>
              </div>
              <div class="value">{{ orderStat.dayOrderMemberNum }}</div>
              <div class="cmpday">
                <span class="sub-title">较昨天</span>
                <span :class="['sub-value', handleStat(orderStat.cmpDayOrderMemberNum) >= 0 ? 'plus' : 'minus']">{{
                  orderStat.cmpDayOrderMemberNum
                }}</span>
                <span class="icon">
                  <i v-if="handleStat(orderStat.cmpDayOrderMemberNum) >= 0" class="el-icon-top plus"></i>
                  <i v-else class="el-icon-bottom minus"></i>
                </span>
              </div>
            </div>
            <div class="right">
              <div class="title">客户类型</div>
              <div class="chart">
                <div class="bar" v-if="orderStat.dayOrderMemberTypeRatioList">
                  <BarChart v-if="show" :list="orderStat.dayOrderMemberTypeRatioList"></BarChart>
                </div>
                <div class="desc">
                  <div class="desc-item">
                    <span class="value">{{ xinke }}%</span>
                    <span class="label">新客占比</span>
                  </div>
                  <div class="desc-item">
                    <span class="value">{{ laoke }}%</span>
                    <span class="label">在租占比</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="left">
              <div class="title">近30日下单用户数</div>
              <div class="value">{{ orderStat.day30OrderMemberNum }}</div>
              <div class="cmpday">
                <span class="sub-title">同比</span>
                <span :class="['sub-value', handleStat(orderStat.cmpDay30OrderMemberNum) >= 0 ? 'plus' : 'minus']">{{
                  orderStat.cmpDay30OrderMemberNum
                }}</span>
                <span class="icon">
                  <i v-if="handleStat(orderStat.cmpDay30OrderMemberNum) >= 0" class="el-icon-top plus"></i>
                  <i v-else class="el-icon-bottom minus"></i>
                </span>
              </div>
            </div>
            <div class="right">
              <div class="chart">
                <div class="bar" v-if="orderStat.day30OrderMemberTypeRatioList">
                  <BarChart v-if="show" :list="orderStat.day30OrderMemberTypeRatioList"></BarChart>
                </div>
                <div class="desc">
                  <div class="desc-item">
                    <span class="value">{{ xinke30 }}%</span>
                    <span class="label">新客占比</span>
                  </div>
                  <div class="desc-item">
                    <span class="value">{{ laoke30 }}%</span>
                    <span class="label">在租占比</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- 第二列 -->
        <div class="item bg2 item animate__animated animate__zoomIn animate__faster">
          <div class="row">
            <div class="col">
              <div class="title">
                <el-tooltip effect="dark" content="今日下单订单量" placement="top-start">
                  <span class="xiadan">
                    日下单量 <span class="num"> {{ orderStat.dayOrderNum }} </span>
                  </span>
                </el-tooltip>
              </div>
              <div class="value">
                <!-- {{ orderStat.dayOrderNum }} -->
              </div>
              <div class="cmpday">
                <span class="sub-title">较昨天</span>
                <span :class="['sub-value', handleStat(orderStat.cmpDayOrderNum) >= 0 ? 'plus' : 'minus']">{{
                  orderStat.cmpDayOrderNum
                }}</span>
                <span class="icon">
                  <i v-if="handleStat(orderStat.cmpDayOrderNum) >= 0" class="el-icon-top plus"></i>
                  <i v-else class="el-icon-bottom minus"></i>
                </span>
              </div>
            </div>
            <div class="col">
              <div class="title">近30日下单量</div>
              <div class="value">{{ orderStat.day30OrderNum }}</div>
              <div class="cmpday">
                <span class="sub-title">
                  <el-tooltip effect="dark" content="（本周期 - 上个周期) / 上个周期 × 100%" placement="top-start">
                    <span>同比</span>
                  </el-tooltip>
                </span>
                <span :class="['sub-value', handleStat(orderStat.cmpDay30OrderNum) >= 0 ? 'plus' : 'minus']">{{
                  orderStat.cmpDay30OrderNum
                }}</span>
                <span class="icon">
                  <i v-if="handleStat(orderStat.cmpDay30OrderNum) >= 0" class="el-icon-top plus"></i>
                  <i v-else class="el-icon-bottom minus"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="line-chart" v-if="orderStat.day30OrderNumList">
            <LineChart
              v-if="show"
              :list_x="orderStat.day30OrderNumList.xaxis"
              :list_y="orderStat.day30OrderNumList.yaxis"
              color1="#9fe080"
              color2="#f3fef1"
              title="下单量"
              dataType="number"
            ></LineChart>
          </div>
        </div>
        <!-- 第3列 -->
        <div class="item bg3 item animate__animated animate__zoomIn animate__faster">
          <div class="row">
            <div class="col">
              <div class="title">
                <el-tooltip effect="dark" content="今日下单且支付首付订单数" placement="top-start">
                  <span> 日成交订单量</span>
                </el-tooltip>
              </div>
              <div class="value">{{ orderStat.dayOrderDealNum }}</div>
              <div class="cmpday">
                <span class="sub-title">较昨天</span>
                <span :class="['sub-value', handleStat(orderStat.cmpDayOrderDealNum) >= 0 ? 'plus' : 'minus']">{{
                  orderStat.cmpDayOrderDealNum
                }}</span>
                <span class="icon">
                  <i v-if="handleStat(orderStat.cmpDayOrderDealNum) >= 0" class="el-icon-top plus"></i>
                  <i v-else class="el-icon-bottom minus"></i>
                </span>
              </div>
            </div>
            <div class="col">
              <div class="title">近30日成单量</div>
              <div class="value">{{ orderStat.day30OrderDealNum }}</div>
              <div class="cmpday">
                <span class="sub-title">
                  <el-tooltip effect="dark" content="（本周期 - 上个周期) / 上个周期 × 100%" placement="top-start">
                    <span>同比</span>
                  </el-tooltip>
                </span>
                <span :class="['sub-value', handleStat(orderStat.cmpDay30OrderDealNum) >= 0 ? 'plus' : 'minus']">{{
                  orderStat.cmpDay30OrderDealNum
                }}</span>
                <span class="icon">
                  <i v-if="handleStat(orderStat.cmpDay30OrderDealNum) >= 0" class="el-icon-top plus"></i>
                  <i v-else class="el-icon-bottom minus"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="line-chart" v-if="orderStat.day30OrderDealNumList">
            <LineChart
              v-if="show"
              :list_x="orderStat.day30OrderDealNumList.xaxis"
              :list_y="orderStat.day30OrderDealNumList.yaxis"
              color1="#5c7bd9"
              color2="#e9f5fe"
              title="成单量"
              dataType="number"
            ></LineChart>
          </div>
        </div>
        <!-- 第4列 -->
        <div class="item bg4 item animate__animated animate__zoomIn animate__faster">
          <div class="row">
            <div class="col">
              <div class="title">
                <el-tooltip effect="dark" content="日成交订单量/日下单量" placement="top-start">
                  <span>日成交转化率</span>
                </el-tooltip>
              </div>
              <div class="value">{{ orderStat.dayOrderDealRate }}</div>
              <div class="cmpday">
                <span class="sub-title">较昨天</span>
                <span :class="['sub-value', handleStat(orderStat.cmpDayOrderDealRate) >= 0 ? 'plus' : 'minus']">{{
                  orderStat.cmpDayOrderDealRate
                }}</span>
                <span class="icon">
                  <i v-if="handleStat(orderStat.cmpDayOrderDealRate) >= 0" class="el-icon-top plus"></i>
                  <i v-else class="el-icon-bottom minus"></i>
                </span>
              </div>
            </div>
            <div class="col">
              <div class="title">近30日成交转化率</div>
              <div class="value">{{ orderStat.day30OrderDealRate }}</div>
              <div class="cmpday">
                <span class="sub-title">
                  <el-tooltip effect="dark" content="（本周期 - 上个周期) / 上个周期 × 100%" placement="top-start">
                    <span>同比</span>
                  </el-tooltip>
                </span>
                <span :class="['sub-value', handleStat(orderStat.cmpDay30OrderDealRate) >= 0 ? 'plus' : 'minus']">{{
                  orderStat.cmpDay30OrderDealRate
                }}</span>
                <span class="icon">
                  <i v-if="handleStat(orderStat.cmpDay30OrderDealRate) >= 0" class="el-icon-top plus"></i>
                  <i v-else class="el-icon-bottom minus"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="line-chart" v-if="orderStat.day30OrderDealRateList">
            <LineChart
              v-if="show"
              :list_x="orderStat.day30OrderDealRateList.xaxis"
              :list_y="orderStat.day30OrderDealRateList.yaxis"
              color1="#9373ee"
              color2="#f4f4ff"
              title="转化率"
              dataType="percent"
            ></LineChart>
          </div>
        </div>
        <!-- 第5列 -->
        <div class="item bg5 item animate__animated animate__zoomIn animate__faster">
          <div class="row">
            <div class="col">
              <div class="title">
                <el-tooltip effect="dark" content="日账单缴纳率" placement="top-start">
                  <span>日账单缴纳率</span>
                </el-tooltip>
              </div>
              <div class="value">{{ orderStat.dayBillPayRate }}</div>
              <div class="cmpday">
                <span class="sub-title">较昨天</span>
                <span :class="['sub-value', handleStat(orderStat.cmpDayBillPayRate) >= 0 ? 'plus' : 'minus']">{{
                  orderStat.cmpDayBillPayRate
                }}</span>
                <span class="icon">
                  <i v-if="handleStat(orderStat.cmpDayBillPayRate) >= 0" class="el-icon-top plus"></i>
                  <i v-else class="el-icon-bottom minus"></i>
                </span>
              </div>
            </div>
            <div class="col">
              <div class="title">近30日日账单缴纳率</div>
              <div class="value">{{ orderStat.day30BillPayRate }}</div>
              <div class="cmpday">
                <span class="sub-title">
                  <el-tooltip effect="dark" content="（本周期 - 上个周期) / 上个周期 × 100%" placement="top-start">
                    <span>同比</span>
                  </el-tooltip>
                </span>
                <span :class="['sub-value', handleStat(orderStat.cmpDay30BillPayRate) >= 0 ? 'plus' : 'minus']">{{
                  orderStat.cmpDay30BillPayRate
                }}</span>
                <span class="icon">
                  <i v-if="handleStat(orderStat.cmpDay30BillPayRate) >= 0" class="el-icon-top plus"></i>
                  <i v-else class="el-icon-bottom minus"></i>
                </span>
              </div>
            </div>
          </div>
          <div class="line-chart" v-if="orderStat.day30BillPayRateList">
            <LineChart
              v-if="show"
              :list_x="orderStat.day30BillPayRateList.xaxis"
              :list_y="orderStat.day30BillPayRateList.yaxis"
              color1="#e6a23c"
              color2="#fae7d2"
              title="缴纳率"
              dataType="percent"
            ></LineChart>
          </div>
        </div>
      </div>
      <admin-title title="日账单数据"></admin-title>
      <Bill v-if="orderBillStat" :bill="orderBillStat"></Bill>
      <admin-title title="收支数据"></admin-title>
      <ShouZhi v-if="financeStat" :detail="financeStat"></ShouZhi>
      <admin-title title="门店数据"></admin-title>
      <Store :detail="storeStat"></Store>
    </template>
    <!-- 无权限 -->
    <template v-else>
      <el-empty description="敬请期待"></el-empty>
    </template>
  </div>
</template>

<script>
import { getHomeStatAPI } from './api'
import { debounce } from '@/utils/index.js'
import BarChart from './BarChart/BarCharts.vue'
import LineChart from './LineChart/LineCharts.vue'
import Bill from './bill.vue'
import ShouZhi from './shouzhi.vue'
import Store from './store.vue'
export default {
  name: 'Home',
  components: { BarChart, LineChart, Bill, ShouZhi, Store },
  data() {
    return {
      show: false,
      current_date: 1,
      stat: {},
      orderStat: {},
      orderBillStat: {},
      financeStat: {},
      storeStat: {}
    }
  },
  computed: {
    is_auth() {
      const authMenus = this.$store.state.user.authMenus
      const is_admin = this.$store.state.user.isAdmin
      const res = authMenus.indexOf('Home')
      if (is_admin == 1 || res > -1) return true
      return false
    },
    today() {
      // 获取当前日期
      let today = new Date()

      // 减去一天
      today.setDate(today.getDate() - 0)

      // 格式化为YYYY-MM-DD格式
      let res = today.toISOString().split('T')[0]
      return res
    },
    today1() {
      // 获取当前日期
      let today = new Date()

      // 减去一天
      today.setDate(today.getDate() - 1)

      // 格式化为YYYY-MM-DD格式
      let res = today.toISOString().split('T')[0]
      return res
    },
    today2() {
      // 获取当前日期
      let today = new Date()

      // 减去一天
      today.setDate(today.getDate() - 2)

      // 格式化为YYYY-MM-DD格式
      let res = today.toISOString().split('T')[0]
      return res
    },
    // 在租占比-日
    laoke() {
      const { dayOrderMemberTypeRatioList } = this.orderStat
      if (!dayOrderMemberTypeRatioList) return 0
      const total = dayOrderMemberTypeRatioList.reduce((total, item) => total + item.value, 0) - 0
      if (!total) return 0
      const value = dayOrderMemberTypeRatioList.find(item => item.name == '在租')
      if (!value) return 0
      return ((value.value / total) * 100).toFixed(2)
    },
    // 新客占比-日
    xinke() {
      const { dayOrderMemberTypeRatioList } = this.orderStat
      if (!dayOrderMemberTypeRatioList) return 0
      const total = dayOrderMemberTypeRatioList.reduce((total, item) => total + item.value, 0) - 0
      if (!total) return 0
      const value = dayOrderMemberTypeRatioList.find(item => item.name == '新客')
      if (!value) return 0
      return ((value.value / total) * 100).toFixed(2)
    },
    // 在租占比-30日
    laoke30() {
      const { day30OrderMemberTypeRatioList } = this.orderStat
      if (!day30OrderMemberTypeRatioList) return 0
      const total = day30OrderMemberTypeRatioList.reduce((total, item) => total + item.value, 0) - 0
      if (!total) return 0
      const value = day30OrderMemberTypeRatioList.find(item => item.name == '在租')
      if (!value) return 0
      return ((value.value / total) * 100).toFixed(2)
    },
    // 新客占比-30日
    xinke30() {
      const { day30OrderMemberTypeRatioList } = this.orderStat
      if (!day30OrderMemberTypeRatioList) return 0
      const total = day30OrderMemberTypeRatioList.reduce((total, item) => total + item.value, 0) - 0
      if (!total) return 0
      const value = day30OrderMemberTypeRatioList.find(item => item.name == '新客')
      if (!value) return 0
      return ((value.value / total) * 100).toFixed(2)
    }
  },
  watch: {
    current_date: {
      immediate: true,
      handler(newV, oldV) {
        this.getHomeStat()
      }
    }
  },
  mounted() {
    window.onresize = debounce(() => {
      if (!this.show) return
      this.getHomeStat()
    }, 500)
  },
  filters: {
    todayName(val) {
      let arr = val.split('-')
      return arr[1] + '月' + arr[2] + '日'
    }
  },

  methods: {
    async getHomeStat() {
      if (!this.is_auth) return
      this.show = false
      let params = { date: this.today }
      if (this.current_date == 1) {
        params.date = this.today
      }
      if (this.current_date == 2) {
        params.date = this.today1
      }
      if (this.current_date == 3) {
        params.date = this.today2
      }
      const res = await getHomeStatAPI(params)
      this.stat = res
      this.orderStat = res.orderStat
      this.orderBillStat = res.orderBillStat
      this.financeStat = res.financeStat
      this.storeStat = res.storeStat
      this.show = true
    },
    handleStat(str) {
      if (!str || str === '-') return 0
      return parseFloat(str.trimEnd('%'))
    }
  }
}
</script>

<style lang="scss" scoped>
.wrapper {
  width: 100%;
  $red: #f56c6c;
  $green: #67c23a;
  .plus {
    color: $red;
  }
  .minus {
    color: $green;
  }
  .bg1 {
    background: linear-gradient(to bottom, #f2f9fe, #e6f4fe);
  }
  .bg2 {
    background: linear-gradient(to bottom, #f4fef1, #e7feee);
  }
  .bg3 {
    background: linear-gradient(to bottom, #f1f8fe, #e6f4fe);
  }
  .bg4 {
    background: linear-gradient(to bottom, #f5f5ff, #ececff);
  }
  .bg5 {
    background: linear-gradient(to bottom, #f6f6fc, #fae8d2);
  }
  .top /deep/ {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .right {
      .date {
        margin-top: 5px;
      }
    }
    .title {
      margin-bottom: 0;
    }
  }
  .hexin-box {
    display: grid;
    margin-top: 20px;
    margin-bottom: 20px;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    justify-content: center;
    .item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px;
      border-radius: 5px;
      height: 220px;
      .row {
        flex: 1;
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        &:last-child {
          margin-bottom: 0;
        }
        .left,
        .col {
          // flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .title {
            // text-align: center;
            font-size: 14px;
            .xiadan {
              position: relative;
              .num {
                position: absolute;
                bottom: -42px;
                left: 50%;
                transform: translateX(-50%);
                font-weight: 700;
                margin: 5px 0;
                // text-align: center;
                font-size: 22px;
              }
            }
          }
          .value {
            position: relative;
            font-weight: 700;
            margin: 5px 0;
            text-align: center;
            font-size: 22px;
          }
          .cmpday {
            display: flex;
            align-items: center;
            justify-content: center;
            .sub-title {
              font-size: 12px;
              color: #888;
            }
            .sub-value {
              font-size: 12px;
              margin: 0 5px;
            }
            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              i {
                font-size: 15px;
              }
            }
          }
        }

        .right {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          min-width: 150px;
          // flex: 1;
          .title {
            padding-left: 20px;
            text-align: left;
          }
          .chart {
            display: flex;
            align-items: center;
            .bar {
              width: 80px;
              height: 80px;
            }
            .desc {
              display: flex;
              flex-direction: column;
              margin-left: 10px;
              font-size: 12px;
              .desc-item {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 5px;
                &:last-child {
                  margin-bottom: 0;
                }
                .label {
                  font-size: 10px;
                  color: #888;
                }
                .value {
                  font-weight: 600;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .line-chart {
        // background: pink;
        height: 110px;
        width: 100%;
      }
    }
  }
}
</style>
