<template>
  <div class="wrapper">
    <!-- 正常到期帐单数 -->
    <div class="item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.normalBillNum }}</div>
      <div class="label">正常到期帐单数</div>
      <div class="tip">
        <el-tooltip effect="dark" content="账单日为今日的非延期账单的数量" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 正常到期账单金额 -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.normalBillAmount }}</div>
      <div class="label">正常到期账单金额</div>
      <div class="tip">
        <el-tooltip effect="dark" content="账单日为今日的非延期账单的总金额" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 正常账单实际支付数 -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.normalPayBillNum }}</div>
      <div class="label">正常账单实际支付数</div>
      <div class="tip">
        <el-tooltip effect="dark" content="今日到期的非延期账单用户实际支付完成的账单数" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 正常账单实际支金额 -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.normalPayBillAmount }}</div>
      <div class="label">正常账单实际支付金额</div>
      <div class="tip">
        <el-tooltip effect="dark" content="今日到期的非延期账单用户实际支付完成的账单总金额" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 正常账单缴纳率 -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.normalBillNumRate }}</div>
      <div class="label">正常账单缴纳率</div>
      <div class="tip">
        <el-tooltip effect="dark" content="正常账单实际支付数/正常到期账单数" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 正常账单金额回收率 -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.normalBillAmountRate }}</div>
      <div class="label">正常账单金额回收率</div>
      <div class="tip">
        <el-tooltip effect="dark" content="正常账单实际支付金额/正常到期账单金额" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 延期账单到期数 -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.delayBillNum }}</div>
      <div class="label">延期账单到期数</div>
      <div class="tip">
        <el-tooltip effect="dark" content="延期后账单日在今日到期的账单数量" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 延期账单到期金额  -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.delayBillAmount }}</div>
      <div class="label">延期账单到期金额</div>
      <div class="tip">
        <el-tooltip effect="dark" content="延期后账单日在今日到期的账单总金额" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 延期账单实际支付数  -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.delayPayBillNum }}</div>
      <div class="label">延期账单实际支付数</div>
      <div class="tip">
        <el-tooltip effect="dark" content="延期后账单日在今日到期的账单用户实际支付的账单数量" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 延期账单实际支付金额  -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.delayPayBillAmount }}</div>
      <div class="label">延期账单实际支付金额</div>
      <div class="tip">
        <el-tooltip effect="dark" content="延期后账单日在今日到期的账单用户实际支付的账单金额" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 延期账单缴纳率  -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.delayBillNumRate }}</div>
      <div class="label">延期账单缴纳率</div>
      <div class="tip">
        <el-tooltip effect="dark" content="延期账单实际支付数/延期账单到期数" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 延期账单金额回收率  -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.delayBillAmountRate }}</div>
      <div class="label">延期账单金额回收率</div>
      <div class="tip">
        <el-tooltip effect="dark" content="延期账单实际支付金额/延期账单到期金额" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 逾期支付账单数  -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.overduePayBillNum }}</div>
      <div class="label">逾期支付账单数</div>
      <div class="tip">
        <el-tooltip effect="dark" content="逾期账单在当日支付的账单数" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
    <!-- 逾期支付账单金额  -->
    <div class="item item animate__animated animate__zoomIn animate__faster">
      <div class="value">{{ bill.overduePayBillAmount }}</div>
      <div class="label">逾期支付账单金额</div>
      <div class="tip">
        <el-tooltip effect="dark" content="已逾期账单今日支付账单总金额" placement="top-start">
          <i class="el-icon-warning-outline"></i>
        </el-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bill',
  props: {
    bill: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {}
  },

  mounted() {},

  methods: {}
}
</script>
<style lang="scss" scoped>
.wrapper {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(6, minmax(190px, 1fr));
  margin-bottom: 20px;
  .item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    border: 1px solid #ebeef5;
    padding: 10px 20px;
    border-radius: 4px;
    &:hover {
      background: #f0f2f5;
    }
    .value {
      font-size: 26px;
      font-weight: 700;
      color: #333;
    }
    .label {
      color: #98a9bc;
    }
    .tip {
      position: absolute;
      top: 5px;
      right: 5px;
      i {
        font-size: 18px;
      }
    }
  }
}
</style>
